var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "index" }, [
    _vm._m(0),
    _c("div", { staticClass: "content" }, [
      _c("div", { staticClass: "left" }, [
        _c("ul", [
          _c(
            "li",
            {
              class: _vm.active == 1 ? "active" : "",
              on: {
                click: function($event) {
                  return _vm.onClick1(1)
                }
              }
            },
            [
              _c("img", {
                staticClass: "demonstrate",
                attrs: {
                  src: require("../../../assets/newIndex/h_daima.png"),
                  alt: "logo"
                }
              }),
              _vm._v("培训与演示\n        ")
            ]
          ),
          _c(
            "li",
            {
              class: _vm.active == 2 ? "active" : "",
              on: {
                click: function($event) {
                  return _vm.onClick1(2)
                }
              }
            },
            [
              _c("img", {
                staticClass: "demonstrate",
                attrs: {
                  src: require("../../../assets/newIndex/h_hope.png"),
                  alt: "logo"
                }
              }),
              _vm._v("帮助中心\n        ")
            ]
          ),
          _c(
            "li",
            {
              class: _vm.active == 3 ? "active" : "",
              on: {
                click: function($event) {
                  return _vm.onClick1(3)
                }
              }
            },
            [
              _c("img", {
                staticClass: "demonstrate",
                attrs: {
                  src: require("../../../assets/newIndex/h_video.png"),
                  alt: "logo"
                }
              }),
              _vm._v("视频小课堂\n        ")
            ]
          ),
          _c(
            "li",
            {
              class: _vm.active == 4 ? "active" : "",
              on: {
                click: function($event) {
                  return _vm.onClick1(4)
                }
              }
            },
            [
              _c("img", {
                staticClass: "demonstrate",
                attrs: {
                  src: require("../../../assets/newIndex/h_download.png"),
                  alt: "logo"
                }
              }),
              _vm._v("下载中心\n        ")
            ]
          ),
          _c(
            "li",
            {
              class: _vm.active == 5 ? "active" : "",
              on: {
                click: function($event) {
                  return _vm.onClick1(5)
                }
              }
            },
            [
              _c("img", {
                staticClass: "demonstrate",
                attrs: {
                  src: require("../../../assets/newIndex/h_baoxiu.png"),
                  alt: "logo"
                }
              }),
              _vm._v("保修承诺\n        ")
            ]
          )
        ])
      ]),
      _c("div", { staticClass: "right" }, [
        _vm.active == 1
          ? _c("ul", [
              _c(
                "li",
                {
                  on: {
                    click: function($event) {
                      return _vm.onOpenDownload(
                        "/static/2023年秋季巡回沟通会V1.0.pdf"
                      )
                    }
                  }
                },
                [
                  _c("div", { staticClass: "line" }),
                  _vm._v("\n          2023年秋季巡回沟通会V1.0.pdf\n        ")
                ]
              )
            ])
          : _vm._e(),
        _vm.active == 2
          ? _c("ul", [_vm._m(1), _vm._m(2), _vm._m(3), _vm._m(4), _vm._m(5)])
          : _vm._e(),
        _vm.active == 3
          ? _c("ul", [
              _c(
                "li",
                {
                  on: {
                    click: function($event) {
                      return _vm.onHref(
                        "https://mp.weixin.qq.com/s/lU6yinZISBTt3TKy_-PUxQ"
                      )
                    }
                  }
                },
                [
                  _c("div", { staticClass: "line" }),
                  _vm._v("\n          软件系统三端实操演示短片\n        ")
                ]
              ),
              _c(
                "li",
                {
                  on: {
                    click: function($event) {
                      return _vm.onHref(
                        "https://mp.weixin.qq.com/s/Jv-gnWJUmyclXGitl44MFg"
                      )
                    }
                  }
                },
                [
                  _c("div", { staticClass: "line" }),
                  _vm._v("\n          三分钟使用指南之管理端小程序\n        ")
                ]
              ),
              _c(
                "li",
                {
                  on: {
                    click: function($event) {
                      return _vm.onHref(
                        "https://mp.weixin.qq.com/s/qehjy4-m3Q9Dqs_dfpnyZw"
                      )
                    }
                  }
                },
                [
                  _c("div", { staticClass: "line" }),
                  _vm._v(
                    "\n          视频中心|使用场景实验通学生篇（一）\n        "
                  )
                ]
              ),
              _c(
                "li",
                {
                  on: {
                    click: function($event) {
                      return _vm.onHref(
                        "https://mp.weixin.qq.com/s/55W8LYAs0aNHwburYIO7LA"
                      )
                    }
                  }
                },
                [
                  _c("div", { staticClass: "line" }),
                  _vm._v("\n          三分钟使用指南之管理后台\n        ")
                ]
              )
            ])
          : _vm._e(),
        _vm.active == 4
          ? _c("ul", [
              _c(
                "li",
                {
                  on: {
                    click: function($event) {
                      return _vm.onDownload(
                        "/static/毅力实验通操作用户使用指南V2.3.1.docx"
                      )
                    }
                  }
                },
                [
                  _c("div", { staticClass: "line" }),
                  _vm._v(
                    "\n          毅力实验通操作用户使用指南V2.3.1\n        "
                  )
                ]
              ),
              _c(
                "li",
                {
                  on: {
                    click: function($event) {
                      return _vm.onDownload(
                        "/static/院校基础信息录入表及填写说明V23.6.docx"
                      )
                    }
                  }
                },
                [
                  _c("div", { staticClass: "line" }),
                  _vm._v(
                    "\n          院校基础信息录入表及填写说明V23.6\n        "
                  )
                ]
              ),
              _c(
                "li",
                {
                  on: {
                    click: function($event) {
                      return _vm.onDownload(
                        "/static/黑色控制板绑定入库方法.pptx"
                      )
                    }
                  }
                },
                [
                  _c("div", { staticClass: "line" }),
                  _vm._v("\n          黑色控制板绑定入库方法\n        ")
                ]
              )
            ])
          : _vm._e(),
        _vm.active == 5
          ? _c("ul", [
              _vm._m(6),
              _vm._m(7),
              _vm._m(8),
              _vm._m(9),
              _vm._m(10),
              _vm._m(11),
              _vm._m(12),
              _vm._m(13),
              _vm._m(14),
              _vm._m(15),
              _vm._m(16),
              _vm._m(17),
              _vm._m(18),
              _vm._m(19),
              _vm._m(20),
              _vm._m(21),
              _vm._m(22),
              _vm._m(23),
              _vm._m(24),
              _vm._m(25),
              _vm._m(26),
              _vm._m(27),
              _vm._m(28),
              _vm._m(29),
              _vm._m(30),
              _vm._m(31),
              _vm._m(32),
              _vm._m(33),
              _vm._m(34),
              _vm._m(35),
              _vm._m(36)
            ])
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "banner" }, [
      _c("div", { staticClass: "txt" }, [_vm._v("我们能帮上什么忙？")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("div", { staticClass: "line" }),
      _c("div", [
        _vm._v("\n          1.为什么手机扫码开锁很慢很慢的？"),
        _c("br"),
        _c("div", { staticClass: "answer" }, [
          _vm._v(
            "手机扫码开锁慢有两个原因，一个可能是因为手机系统过老，运行速度慢导致的，另一个可能因手机连接其它蓝牙设备，我们的锁使用的蓝牙为低功耗型蓝牙，与普通蓝牙互相干扰，导致蓝牙连接频率降低，所以手机扫码开锁很慢。"
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("div", { staticClass: "line" }),
      _c("div", [
        _vm._v("2.开锁显示失败，但是锁开启了？"),
        _c("br"),
        _c("div", { staticClass: "answer" }, [
          _vm._v(
            "可能是锁扣弹开的力度不够，门柜过重导致未弹开，检测锁状态时检测到的是未打开，才会显示失败的。"
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("div", { staticClass: "line" }),
      _c("div", [
        _vm._v("3.为什么我的的借用申请没有通过呢？"),
        _c("br"),
        _c("div", { staticClass: "answer" }, [
          _vm._v(
            "现阶段我们都是通过管理员后台审核大家的申请的，如果显示没通过，建议您咨询您的实验室管理老师。"
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("div", { staticClass: "line" }),
      _c("div", [
        _vm._v(" 4.超期未归还有什么样的惩罚措施或者补救措施吗？"),
        _c("br"),
        _c("div", { staticClass: "answer" }, [
          _vm._v(
            "请参阅实验室借还管理制度，并尽快联系规划设备以免给您造成不良后果。"
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("div", { staticClass: "line" }),
      _c("div", [
        _vm._v("5.归还设备我能快速离开现场吗？"),
        _c("br"),
        _c("div", { staticClass: "answer" }, [
          _vm._v(
            "建议您参考小程序提示反馈确认归还后缓步离开，如有故障请及时联系管理老师。"
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticStyle: {
          margin: "0 0 21px",
          "font-family": "'Times New Roman'",
          "font-size": "16px",
          "text-wrap": "wrap",
          "line-height": "21px"
        }
      },
      [
        _c(
          "span",
          {
            staticStyle: {
              "font-family": "微软雅黑",
              color: "rgb(51, 51, 51)",
              "font-size": "14px"
            }
          },
          [
            _c("span", { staticStyle: { "font-family": "微软雅黑" } }, [
              _vm._v(
                "尊敬的客户：感谢您选择毅力实验通产品和服务！就本文件项下产品，我们将向您提供以下保证和保修服务。本文件所称的"
              )
            ]),
            _c("span", { staticStyle: { "font-family": "微软雅黑" } }, [
              _vm._v(
                "“您”是指购买产品的任何个人或机构。本文件中所称的“我们”是指毅力实验通及服务提供商。"
              )
            ])
          ]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticStyle: {
          margin: "0 0 16px",
          "font-family": "'Times New Roman'",
          "font-size": "16px",
          "text-wrap": "wrap"
        }
      },
      [
        _c("strong", [
          _c(
            "span",
            { staticStyle: { "font-family": "微软雅黑", "font-size": "18px" } },
            [_vm._v("1.0保证范围")]
          )
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticStyle: {
          "font-family": "'Times New Roman'",
          "font-size": "16px",
          "text-wrap": "wrap",
          "line-height": "21px"
        }
      },
      [
        _c(
          "span",
          {
            staticStyle: {
              "font-family": "微软雅黑",
              color: "rgb(51, 51, 51)",
              "font-size": "14px"
            }
          },
          [
            _c("span", { staticStyle: { "font-family": "微软雅黑" } }, [
              _vm._v(
                "我们保证，如产品在正常使用和维护条件下，因材料、工艺或制造问题导致性能故障，在保修期内，我们将按本文件规定为您提供"
              )
            ]),
            _c("span", { staticStyle: { "font-family": "微软雅黑" } }, [
              _vm._v("修理、更换等保修服务。")
            ]),
            _c("span", { staticStyle: { "font-family": "微软雅黑" } }, [
              _vm._v(
                "“正常使用和维护条件”是指产品的安装、使用、维护、保管、运输等符合使用说明要求，并用于合理的\n              预期目的或用途。“影响正常使用”是指产品不能实现其标准性能参数所描述的功能。 "
              )
            ])
          ]
        ),
        _c("strong", [
          _c(
            "span",
            {
              staticStyle: {
                "font-family": "微软雅黑",
                color: "rgb(51, 51, 51)",
                "font-size": "14px"
              }
            },
            [
              _vm._v(
                "除事先声明或依法或依约定应由您负担费用外，我们不就保修服务向您另外收取费用。以上是我们就产品质量和服务所做的全部保证，无其他明示或默示的保证。"
              )
            ]
          )
        ]),
        _c(
          "span",
          {
            staticStyle: {
              "font-family": "微软雅黑",
              color: "rgb(51, 51, 51)",
              "font-size": "14px"
            }
          },
          [
            _vm._v(
              " 如销售商或其他任何第三方对您有额外承诺，您应向这些第三方要求兑现，毅力实验通对此不承担责任。"
            )
          ]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticStyle: {
          margin: "0 0 16px",
          "font-family": "'Times New Roman'",
          "font-size": "16px",
          "text-wrap": "wrap"
        }
      },
      [
        _c("strong", [
          _c(
            "span",
            { staticStyle: { "font-family": "微软雅黑", "font-size": "18px" } },
            [_vm._v("2.0保修期")]
          )
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticStyle: {
          "font-family": "'Times New Roman'",
          "font-size": "16px",
          "text-wrap": "wrap",
          "line-height": "21px"
        }
      },
      [
        _c(
          "span",
          {
            staticStyle: {
              "font-family": "微软雅黑",
              color: "rgb(51, 51, 51)",
              "font-size": "14px"
            }
          },
          [_vm._v("2.1产品及部件的相应保修期按相关的《")]
        ),
        _c("strong", [
          _c(
            "span",
            {
              staticStyle: {
                "font-family": "微软雅黑",
                color: "rgb(51, 51, 51)",
                "font-size": "14px"
              }
            },
            [_vm._v("毅力实验通产品标准保修期")]
          )
        ]),
        _c(
          "span",
          {
            staticStyle: {
              "font-family": "微软雅黑",
              color: "rgb(51, 51, 51)",
              "font-size": "14px"
            }
          },
          [
            _c("span", { staticStyle: { "font-family": "微软雅黑" } }, [
              _vm._v("》执行。保修期自产品首次购买之日起算，购买日以购买产品")
            ]),
            _c("span", { staticStyle: { "font-family": "微软雅黑" } }, [
              _vm._v(
                "的发票日期为准。如无有效发票，则保修期将自产品出厂日起算。产品发票日期晚于产品实际交付日的，保修期自产品实际交付日起算。"
              )
            ])
          ]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticStyle: {
          margin: "0 0 21px",
          "font-family": "'Times New Roman'",
          "font-size": "16px",
          "text-wrap": "wrap",
          "line-height": "21px"
        }
      },
      [
        _c(
          "span",
          {
            staticStyle: {
              "font-family": "微软雅黑",
              color: "rgb(51, 51, 51)",
              "font-size": "14px"
            }
          },
          [
            _vm._v(
              "2.2在保修范围内修理或更换后的产品或部件，在剩余保修期内继续享有保修，毅力实验通与您签署的合同或适用的相关法律对此另有\n            规定的，则按合同或法律规定执行。"
            )
          ]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticStyle: {
          margin: "0 0 16px",
          "font-family": "'Times New Roman'",
          "font-size": "16px",
          "text-wrap": "wrap"
        }
      },
      [
        _c("strong", [
          _c(
            "span",
            { staticStyle: { "font-family": "微软雅黑", "font-size": "18px" } },
            [_vm._v("3.0如何获得保修服务")]
          )
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticStyle: {
          "font-family": "'Times New Roman'",
          "font-size": "16px",
          "text-wrap": "wrap",
          "line-height": "21px"
        }
      },
      [
        _c(
          "span",
          {
            staticStyle: {
              "font-family": "微软雅黑",
              color: "rgb(51, 51, 51)",
              "font-size": "14px"
            }
          },
          [
            _vm._v(
              "在保修期内，如产品发生性能故障，我们将按本文件提供保修服务，具体服务获取方式："
            )
          ]
        ),
        _c(
          "span",
          {
            staticStyle: {
              "font-family": "微软雅黑",
              color: "rgb(51, 51, 51)",
              "font-size": "14px"
            }
          },
          [_c("br")]
        ),
        _c(
          "span",
          {
            staticStyle: {
              "font-family": "微软雅黑",
              color: "rgb(51, 51, 51)",
              "font-size": "14px"
            }
          },
          [_c("br")]
        ),
        _c(
          "span",
          {
            staticStyle: {
              "font-family": "微软雅黑",
              color: "rgb(51, 51, 51)",
              "font-size": "14px"
            }
          },
          [_vm._v("3.1 ")]
        ),
        _c("strong", [
          _c(
            "span",
            {
              staticStyle: {
                "font-family": "微软雅黑",
                color: "rgb(51, 51, 51)",
                "font-size": "14px"
              }
            },
            [_vm._v("微信公众号支持服务")]
          )
        ]),
        _c(
          "span",
          {
            staticStyle: {
              "font-family": "微软雅黑",
              color: "rgb(51, 51, 51)",
              "font-size": "14px"
            }
          },
          [
            _vm._v(
              "，搜索毅力实验通公众号获取联系方式从而获得电话支持服务，客服热线提供产品使用咨询和故障排查指导服务。"
            )
          ]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticStyle: {
          margin: "0 0 16px",
          "font-family": "'Times New Roman'",
          "font-size": "16px",
          "text-wrap": "wrap"
        }
      },
      [
        _c("strong", [
          _c(
            "span",
            { staticStyle: { "font-family": "微软雅黑", "font-size": "18px" } },
            [_vm._v("4.0保修解决方案")]
          )
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticStyle: {
          margin: "0 0 21px",
          "font-family": "'Times New Roman'",
          "font-size": "16px",
          "text-wrap": "wrap",
          "line-height": "21px"
        }
      },
      [
        _c(
          "span",
          {
            staticStyle: {
              "font-family": "微软雅黑",
              color: "rgb(51, 51, 51)",
              "font-size": "14px"
            }
          },
          [
            _c("span", { staticStyle: { "font-family": "微软雅黑" } }, [
              _vm._v(
                "产品故障以修理为解决原则，在可行的情况下，我们可能会尝试通过网络、电话或其他远程帮助方式来诊断和解决您的产品故障。"
              )
            ]),
            _c("span", { staticStyle: { "font-family": "微软雅黑" } }, [
              _vm._v(
                "如有些问题可通过您自行安装指定的软件更新或替换件方式解决，则我们会指导您获得和安装此类软件更新或替换件。如上述方式"
              )
            ]),
            _c("span", { staticStyle: { "font-family": "微软雅黑" } }, [
              _vm._v("不适用或无法解决问题，将就近安排人员到现场进行支持。")
            ])
          ]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticStyle: {
          margin: "0 0 16px",
          "font-family": "'Times New Roman'",
          "font-size": "16px",
          "text-wrap": "wrap"
        }
      },
      [
        _c("strong", [
          _c(
            "span",
            { staticStyle: { "font-family": "微软雅黑", "font-size": "18px" } },
            [_vm._v("5.0不保证的范围")]
          )
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticStyle: {
          "font-family": "'Times New Roman'",
          "font-size": "16px",
          "text-wrap": "wrap",
          "line-height": "21px"
        }
      },
      [
        _c(
          "span",
          {
            staticStyle: {
              "font-family": "微软雅黑",
              color: "rgb(51, 51, 51)",
              "font-size": "14px"
            }
          },
          [_vm._v("本文件项下的保证不适用于下列情况：")]
        ),
        _c(
          "span",
          {
            staticStyle: {
              "font-family": "微软雅黑",
              color: "rgb(51, 51, 51)",
              "font-size": "14px"
            }
          },
          [_c("br")]
        ),
        _c("strong", [
          _c(
            "span",
            {
              staticStyle: {
                "font-family": "微软雅黑",
                color: "rgb(51, 51, 51)",
                "font-size": "14px"
              }
            },
            [_vm._v("①超出规定的保修期限的；")]
          )
        ]),
        _c("strong", [
          _c(
            "span",
            {
              staticStyle: {
                "font-family": "微软雅黑",
                color: "rgb(51, 51, 51)",
                "font-size": "14px"
              }
            },
            [_c("br")]
          )
        ]),
        _c("strong", [
          _c(
            "span",
            {
              staticStyle: {
                "font-family": "微软雅黑",
                color: "rgb(51, 51, 51)",
                "font-size": "14px"
              }
            },
            [
              _vm._v(
                "②因误用、意外、改装、不适当的物理或操作环境、自然灾害、电涌及不当维护或保管导致的故障或损坏；"
              )
            ]
          )
        ]),
        _c("strong", [
          _c(
            "span",
            {
              staticStyle: {
                "font-family": "微软雅黑",
                color: "rgb(51, 51, 51)",
                "font-size": "14px"
              }
            },
            [_c("br")]
          )
        ]),
        _c("strong", [
          _c(
            "span",
            {
              staticStyle: {
                "font-family": "微软雅黑",
                color: "rgb(51, 51, 51)",
                "font-size": "14px"
              }
            },
            [_vm._v("③第三方产品、软件、服务或行为导致的故障或损坏；")]
          )
        ]),
        _c("strong", [
          _c(
            "span",
            {
              staticStyle: {
                "font-family": "微软雅黑",
                color: "rgb(51, 51, 51)",
                "font-size": "14px"
              }
            },
            [_c("br")]
          )
        ]),
        _c("strong", [
          _c(
            "span",
            {
              staticStyle: {
                "font-family": "微软雅黑",
                color: "rgb(51, 51, 51)",
                "font-size": "14px"
              }
            },
            [_vm._v("④产品使用过程中发生的正常脱色、磨损和消耗；")]
          )
        ]),
        _c("strong", [
          _c(
            "span",
            {
              staticStyle: {
                "font-family": "微软雅黑",
                color: "rgb(51, 51, 51)",
                "font-size": "14px"
              }
            },
            [_c("br")]
          )
        ]),
        _c("strong", [
          _c(
            "span",
            {
              staticStyle: {
                "font-family": "微软雅黑",
                color: "rgb(51, 51, 51)",
                "font-size": "14px"
              }
            },
            [_vm._v("⑤产品可以不间断或无错误地正常运行；")]
          )
        ]),
        _c("strong", [
          _c(
            "span",
            {
              staticStyle: {
                "font-family": "微软雅黑",
                color: "rgb(51, 51, 51)",
                "font-size": "14px"
              }
            },
            [_c("br")]
          )
        ]),
        _c("strong", [
          _c(
            "span",
            {
              staticStyle: {
                "font-family": "微软雅黑",
                color: "rgb(51, 51, 51)",
                "font-size": "14px"
              }
            },
            [_vm._v("⑥数据丢失或损坏；")]
          )
        ]),
        _c("strong", [
          _c(
            "span",
            {
              staticStyle: {
                "font-family": "微软雅黑",
                color: "rgb(51, 51, 51)",
                "font-size": "14px"
              }
            },
            [_c("br")]
          )
        ]),
        _c("strong", [
          _c(
            "span",
            {
              staticStyle: {
                "font-family": "微软雅黑",
                color: "rgb(51, 51, 51)",
                "font-size": "14px"
              }
            },
            [
              _vm._v(
                "⑦消耗零部件，如电池或保护膜等随时间推移而耗损的零部件，除非是因材料或工艺缺陷而发生的故障；"
              )
            ]
          )
        ]),
        _c("strong", [
          _c(
            "span",
            {
              staticStyle: {
                "font-family": "微软雅黑",
                color: "rgb(51, 51, 51)",
                "font-size": "14px"
              }
            },
            [_c("br")]
          )
        ]),
        _c("strong", [
          _c(
            "span",
            {
              staticStyle: {
                "font-family": "微软雅黑",
                color: "rgb(51, 51, 51)",
                "font-size": "14px"
              }
            },
            [
              _vm._v(
                "⑧不能出示产品有效保修凭证和有效原始购物发票或收据，产品原序列号标签有涂改、替换、撕毁的现象、产品没有序列号或保修凭证上的产品型号或编号与产品实物不相符合的；"
              )
            ]
          )
        ]),
        _c("strong", [
          _c(
            "span",
            {
              staticStyle: {
                "font-family": "微软雅黑",
                color: "rgb(51, 51, 51)",
                "font-size": "14px"
              }
            },
            [_c("br")]
          )
        ]),
        _c("strong", [
          _c(
            "span",
            {
              staticStyle: {
                "font-family": "微软雅黑",
                color: "rgb(51, 51, 51)",
                "font-size": "14px"
              }
            },
            [
              _vm._v(
                "⑨未按随附的说明、操作手册使用产品，或者产品未用于预定功能或环境，毅力实验通经证实后确定您违反操作手册的任何其他情况；"
              )
            ]
          )
        ]),
        _c("strong", [
          _c(
            "span",
            {
              staticStyle: {
                "font-family": "微软雅黑",
                color: "rgb(51, 51, 51)",
                "font-size": "14px"
              }
            },
            [_c("br")]
          )
        ]),
        _c("strong", [
          _c(
            "span",
            {
              staticStyle: {
                "font-family": "微软雅黑",
                color: "rgb(51, 51, 51)",
                "font-size": "14px"
              }
            },
            [
              _vm._v(
                "⑩产品的齐备性和外观状态不属保证范围，您应在接受产品时当场检验并对任何不符提出异议。"
              )
            ]
          )
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticStyle: {
          margin: "0 0 16px",
          "font-family": "'Times New Roman'",
          "font-size": "16px",
          "text-wrap": "wrap"
        }
      },
      [
        _c("strong", [
          _c(
            "span",
            { staticStyle: { "font-family": "微软雅黑", "font-size": "18px" } },
            [_vm._v("6.0使用个人信息")]
          )
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticStyle: {
          "font-family": "'Times New Roman'",
          "font-size": "16px",
          "text-wrap": "wrap",
          "line-height": "21px"
        }
      },
      [
        _c("strong", [
          _c(
            "span",
            {
              staticStyle: {
                "font-family": "微软雅黑",
                color: "rgb(51, 51, 51)",
                "font-size": "14px"
              }
            },
            [
              _vm._v(
                "我们可能会保存、使用并处理您提供的保修问题和联系信息，包括姓名、电话号码、地址和电子邮件地址，仅用于提供规定的服务，我们可能会与您联系，进行满意度调查或通知您有关产品召回或安全问题。为此，我们可能会将您的信息发送给我们的相关机构，包括代表我们执行某些业务的实体。我们也可能会依有关法律机构的要求向其披露相关信息。"
              )
            ]
          )
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticStyle: {
          margin: "0 0 16px",
          "font-family": "'Times New Roman'",
          "font-size": "16px",
          "text-wrap": "wrap"
        }
      },
      [
        _c("strong", [
          _c(
            "span",
            { staticStyle: { "font-family": "微软雅黑", "font-size": "18px" } },
            [_vm._v("7.0责任限制")]
          )
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticStyle: {
          "font-family": "'Times New Roman'",
          "font-size": "16px",
          "text-wrap": "wrap",
          "line-height": "21px"
        }
      },
      [
        _c(
          "span",
          {
            staticStyle: {
              "font-family": "微软雅黑",
              color: "rgb(51, 51, 51)",
              "font-size": "14px"
            }
          },
          [
            _vm._v(
              "如毅力实验通违约或需承担其他责任，您可要求毅力实验通赔偿损失。"
            )
          ]
        ),
        _c("strong", [
          _c(
            "span",
            {
              staticStyle: {
                "font-family": "微软雅黑",
                color: "rgb(51, 51, 51)",
                "font-size": "14px"
              }
            },
            [
              _vm._v(
                "但毅力实验通的赔偿责任仅限于您的实际直接损失，且对以下各项不承担任何责任："
              )
            ]
          )
        ]),
        _c("strong", [
          _c(
            "span",
            {
              staticStyle: {
                "font-family": "微软雅黑",
                color: "rgb(51, 51, 51)",
                "font-size": "14px"
              }
            },
            [_c("br")]
          )
        ]),
        _c("strong", [
          _c(
            "span",
            {
              staticStyle: {
                "font-family": "微软雅黑",
                color: "rgb(51, 51, 51)",
                "font-size": "14px"
              }
            },
            [_vm._v("①超出您购买产品实际支付价款的金额；")]
          )
        ]),
        _c("strong", [
          _c(
            "span",
            {
              staticStyle: {
                "font-family": "微软雅黑",
                color: "rgb(51, 51, 51)",
                "font-size": "14px"
              }
            },
            [_c("br")]
          )
        ]),
        _c("strong", [
          _c(
            "span",
            {
              staticStyle: {
                "font-family": "微软雅黑",
                color: "rgb(51, 51, 51)",
                "font-size": "14px"
              }
            },
            [_vm._v("②任何有关获得替代货物、技术、服务或权利的成本和费用；")]
          )
        ]),
        _c("strong", [
          _c(
            "span",
            {
              staticStyle: {
                "font-family": "微软雅黑",
                color: "rgb(51, 51, 51)",
                "font-size": "14px"
              }
            },
            [_c("br")]
          )
        ]),
        _c("strong", [
          _c(
            "span",
            {
              staticStyle: {
                "font-family": "微软雅黑",
                color: "rgb(51, 51, 51)",
                "font-size": "14px"
              }
            },
            [_vm._v("③数据损坏或丢失；")]
          )
        ]),
        _c("strong", [
          _c(
            "span",
            {
              staticStyle: {
                "font-family": "微软雅黑",
                color: "rgb(51, 51, 51)",
                "font-size": "14px"
              }
            },
            [_c("br")]
          )
        ]),
        _c("strong", [
          _c(
            "span",
            {
              staticStyle: {
                "font-family": "微软雅黑",
                color: "rgb(51, 51, 51)",
                "font-size": "14px"
              }
            },
            [
              _vm._v(
                "④使用中断或延迟导致的损失。此责任限制同样适用于产品销售商和服务商。这是毅力实验通及产品销售商和服务商共同承担的最高赔偿限额。"
              )
            ]
          )
        ]),
        _c(
          "span",
          {
            staticStyle: {
              "font-family": "微软雅黑",
              color: "rgb(51, 51, 51)",
              "font-size": "14px"
            }
          },
          [_c("br")]
        ),
        _c(
          "span",
          {
            staticStyle: {
              "font-family": "微软雅黑",
              color: "rgb(51, 51, 51)",
              "font-size": "14px"
            }
          },
          [
            _vm._v(
              "以上责任限制仅在法律允许的范围内适用，不适用于那些依法不能通过合同或其他方式排除或限制的责任。"
            )
          ]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticStyle: {
          margin: "0 0 16px",
          "font-family": "'Times New Roman'",
          "font-size": "16px",
          "text-wrap": "wrap"
        }
      },
      [
        _c("strong", [
          _c(
            "span",
            { staticStyle: { "font-family": "微软雅黑", "font-size": "18px" } },
            [_vm._v("8.0其他权利")]
          )
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticStyle: {
          "font-family": "'Times New Roman'",
          "font-size": "16px",
          "text-wrap": "wrap",
          "line-height": "21px"
        }
      },
      [
        _c("strong", [
          _c(
            "span",
            {
              staticStyle: {
                "font-family": "微软雅黑",
                color: "rgb(51, 51, 51)",
                "font-size": "14px"
              }
            },
            [
              _vm._v(
                "本文件授予您特定的权利，您可能依法还拥有其他权利。本文件的任何内容均不会排除和限制消费者的权利，也不会减轻或免除我们对消费者的责任。"
              )
            ]
          )
        ]),
        _c(
          "span",
          {
            staticStyle: {
              "font-family": "微软雅黑",
              color: "rgb(51, 51, 51)",
              "font-size": "14px"
            }
          },
          [
            _vm._v(
              "如您是为生活消费需要而购买产品的消费者用户，则我们为您提供的保修将不低于法定保修标准。"
            )
          ]
        ),
        _c("strong", [
          _c(
            "span",
            {
              staticStyle: {
                "font-family": "微软雅黑",
                color: "rgb(51, 51, 51)",
                "font-size": "14px"
              }
            },
            [
              _vm._v(
                "法定保修范围内的保修按法律规定执行，法定保修范围外的保修及法律允许另行规定的内容，按本文件执行。"
              )
            ]
          )
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticStyle: {
          margin: "0 0 16px",
          "font-family": "'Times New Roman'",
          "font-size": "16px",
          "text-wrap": "wrap"
        }
      },
      [
        _c("strong", [
          _c(
            "span",
            { staticStyle: { "font-family": "微软雅黑", "font-size": "18px" } },
            [_vm._v("9.0全国联保服务")]
          )
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticStyle: {
          margin: "0 0 21px",
          "font-family": "'Times New Roman'",
          "font-size": "16px",
          "text-wrap": "wrap",
          "line-height": "21px"
        }
      },
      [
        _c(
          "span",
          {
            staticStyle: {
              "font-family": "微软雅黑",
              color: "rgb(51, 51, 51)",
              "font-size": "14px"
            }
          },
          [
            _vm._v(
              "本文件项下的保修仅适用于在中国大陆司法管辖区境内（不包括香港、澳门和台湾）销售的产品，且仅在该区域内提供保修服务。"
            )
          ]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticStyle: {
          margin: "0 0 16px",
          "font-family": "'Times New Roman'",
          "font-size": "16px",
          "text-wrap": "wrap"
        }
      },
      [
        _c("strong", [
          _c(
            "span",
            { staticStyle: { "font-family": "微软雅黑", "font-size": "18px" } },
            [_vm._v("10.0一般条款")]
          )
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticStyle: {
          margin: "0 0 21px",
          "font-family": "'Times New Roman'",
          "font-size": "16px",
          "text-wrap": "wrap",
          "line-height": "21px"
        }
      },
      [
        _c(
          "span",
          {
            staticStyle: {
              "font-family": "微软雅黑",
              color: "rgb(51, 51, 51)",
              "font-size": "14px"
            }
          },
          [
            _vm._v(
              "本文件依照中国大陆司法管辖区的法律制定和解释。本文件中，法律是指国家现行具有强制执行效力的法律规范性文件，保修等同于保证或质量保证，法定保修是指适用于您产品的国家规定，所提及的时限均按法律有关时限的规定解释。如本文件的任何条款被有管辖权的法律机关裁定为部分或全部无效的，不影响其他部分的效力，本文件其他内容在未被裁定为无效的范围内仍然具有完全的效力。"
            )
          ]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticStyle: {
          margin: "0 0 16px",
          "font-family": "'Times New Roman'",
          "font-size": "16px",
          "text-wrap": "wrap"
        }
      },
      [
        _c("strong", [
          _c(
            "span",
            { staticStyle: { "font-family": "微软雅黑", "font-size": "18px" } },
            [_vm._v("11.0毅力实验通产品标准保修期")]
          )
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticStyle: {
          margin: "0 0 21px",
          "font-family": "'Times New Roman'",
          "font-size": "16px",
          "text-wrap": "wrap",
          "line-height": "21px"
        }
      },
      [
        _c(
          "span",
          {
            staticStyle: {
              "font-family": "微软雅黑",
              color: "rgb(51, 51, 51)",
              "font-size": "14px"
            }
          },
          [_vm._v("毅力实验通各系列产品标准保修期限如下：")]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("table", [
      _c("tbody", [
        _c("tr", { staticClass: "firstRow", staticStyle: { height: "36px" } }, [
          _c(
            "td",
            {
              staticStyle: {
                padding: "4px 8px",
                "border-width": "1px",
                "border-color": "rgb(151, 151, 151)"
              },
              attrs: { valign: "center" }
            },
            [
              _c("p", { staticStyle: { "font-size": "16px" } }, [
                _c("strong", [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        "font-family": "微软雅黑",
                        "font-size": "14px"
                      }
                    },
                    [_vm._v("产品大类")]
                  )
                ])
              ])
            ]
          ),
          _c(
            "td",
            {
              staticStyle: {
                padding: "4px 8px",
                "border-left": "none",
                "border-right-width": "1px",
                "border-right-color": "rgb(151, 151, 151)",
                "border-top-width": "1px",
                "border-top-color": "rgb(151, 151, 151)",
                "border-bottom-width": "1px",
                "border-bottom-color": "rgb(151, 151, 151)"
              },
              attrs: { valign: "center" }
            },
            [
              _c("p", { staticStyle: { "font-size": "16px" } }, [
                _c("strong", [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        "font-family": "微软雅黑",
                        "font-size": "14px"
                      }
                    },
                    [_vm._v("产品系列")]
                  )
                ])
              ])
            ]
          ),
          _c(
            "td",
            {
              staticStyle: {
                padding: "4px 8px",
                "border-left": "none",
                "border-right-width": "1px",
                "border-right-color": "rgb(151, 151, 151)",
                "border-top-width": "1px",
                "border-top-color": "rgb(151, 151, 151)",
                "border-bottom-width": "1px",
                "border-bottom-color": "rgb(151, 151, 151)"
              },
              attrs: { valign: "center" }
            },
            [
              _c("p", { staticStyle: { "font-size": "16px" } }, [
                _c("strong", [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        "font-family": "微软雅黑",
                        "font-size": "14px"
                      }
                    },
                    [_vm._v("保修期限")]
                  )
                ])
              ])
            ]
          )
        ]),
        _c("tr", { staticStyle: { height: "36px" } }, [
          _c(
            "td",
            {
              staticStyle: {
                padding: "4px 8px",
                "border-left-width": "1px",
                "border-left-color": "rgb(151, 151, 151)",
                "border-right-width": "1px",
                "border-right-color": "rgb(151, 151, 151)",
                "border-top": "none",
                "border-bottom-width": "1px",
                "border-bottom-color": "rgb(151, 151, 151)"
              },
              attrs: { width: "103", valign: "center" }
            },
            [
              _c("p", { staticStyle: { "font-size": "16px" } }, [
                _c("strong", [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        "font-family": "微软雅黑",
                        "font-size": "14px"
                      }
                    },
                    [_vm._v("智能设备柜")]
                  )
                ])
              ])
            ]
          ),
          _c(
            "td",
            {
              staticStyle: {
                padding: "4px 8px",
                "border-left": "none",
                "border-right-width": "1px",
                "border-right-color": "rgb(151, 151, 151)",
                "border-top": "none",
                "border-bottom-width": "1px",
                "border-bottom-color": "rgb(151, 151, 151)"
              },
              attrs: { width: "376", valign: "center" }
            },
            [
              _c("p", { staticStyle: { "font-size": "16px" } }, [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "font-family": "微软雅黑",
                      "font-size": "14px"
                    }
                  },
                  [_vm._v("毅力实验通智能设备柜标准柜、定制柜")]
                )
              ])
            ]
          ),
          _c(
            "td",
            {
              staticStyle: {
                padding: "4px 8px",
                "border-left": "none",
                "border-right-width": "1px",
                "border-right-color": "rgb(151, 151, 151)",
                "border-top": "none",
                "border-bottom-width": "1px",
                "border-bottom-color": "rgb(151, 151, 151)"
              },
              attrs: { width: "74", valign: "center" }
            },
            [
              _c("p", { staticStyle: { "font-size": "16px" } }, [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "font-family": "微软雅黑",
                      "font-size": "14px"
                    }
                  },
                  [_vm._v("12个月")]
                )
              ])
            ]
          )
        ]),
        _c("tr", { staticStyle: { height: "36px" } }, [
          _c(
            "td",
            {
              staticStyle: {
                padding: "4px 8px",
                "border-left-width": "1px",
                "border-left-color": "rgb(151, 151, 151)",
                "border-right-width": "1px",
                "border-right-color": "rgb(151, 151, 151)",
                "border-top": "none",
                "border-bottom-width": "1px",
                "border-bottom-color": "rgb(151, 151, 151)"
              },
              attrs: { width: "103", valign: "center" }
            },
            [
              _c("p", { staticStyle: { "font-size": "16px" } }, [
                _c("strong", [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        "font-family": "微软雅黑",
                        "font-size": "14px"
                      }
                    },
                    [_vm._v("软件")]
                  )
                ])
              ])
            ]
          ),
          _c(
            "td",
            {
              staticStyle: {
                padding: "4px 8px",
                "border-left": "none",
                "border-right-width": "1px",
                "border-right-color": "rgb(151, 151, 151)",
                "border-top": "none",
                "border-bottom-width": "1px",
                "border-bottom-color": "rgb(151, 151, 151)"
              },
              attrs: { width: "376", valign: "center" }
            },
            [
              _c("p", { staticStyle: { "font-size": "16px" } }, [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "font-family": "微软雅黑",
                      "font-size": "14px"
                    }
                  },
                  [_vm._v("毅力实验通配套软件")]
                )
              ])
            ]
          ),
          _c(
            "td",
            {
              staticStyle: {
                padding: "4px 8px",
                "border-left": "none",
                "border-right-width": "1px",
                "border-right-color": "rgb(151, 151, 151)",
                "border-top": "none",
                "border-bottom-width": "1px",
                "border-bottom-color": "rgb(151, 151, 151)"
              },
              attrs: { width: "74", valign: "center" }
            },
            [
              _c("p", { staticStyle: { "font-size": "16px" } }, [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "font-family": "微软雅黑",
                      "font-size": "14px"
                    }
                  },
                  [_vm._v("6")]
                ),
                _c(
                  "span",
                  {
                    staticStyle: {
                      "font-family": "微软雅黑",
                      "font-size": "14px"
                    }
                  },
                  [_vm._v("0个月")]
                )
              ])
            ]
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticStyle: {
          margin: "0 0 16px",
          "font-family": "'Times New Roman'",
          "font-size": "16px",
          "text-wrap": "wrap"
        }
      },
      [
        _c("strong", [
          _c(
            "span",
            { staticStyle: { "font-family": "微软雅黑", "font-size": "18px" } },
            [_vm._v("*特别说明：")]
          )
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticStyle: {
          margin: "0 0 21px",
          "font-family": "'Times New Roman'",
          "font-size": "16px",
          "text-wrap": "wrap",
          "line-height": "21px"
        }
      },
      [
        _c(
          "span",
          {
            staticStyle: {
              "font-family": "微软雅黑",
              color: "rgb(51, 51, 51)",
              "font-size": "14px"
            }
          },
          [
            _vm._v(
              "①促销产品或非标配类产品的保修政策，请参考促销产品或非标配类产品对应的保修凭证；"
            )
          ]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticStyle: {
          margin: "0 0 21px",
          "font-family": "'Times New Roman'",
          "font-size": "16px",
          "text-wrap": "wrap",
          "line-height": "21px"
        }
      },
      [
        _c(
          "span",
          {
            staticStyle: {
              "font-family": "微软雅黑",
              color: "rgb(51, 51, 51)",
              "font-size": "14px"
            }
          },
          [_vm._v("②特殊项目的产品保修条款以具体购销合同为准；")]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticStyle: {
          margin: "0 0 21px",
          "font-family": "'Times New Roman'",
          "font-size": "16px",
          "text-wrap": "wrap",
          "line-height": "21px"
        }
      },
      [
        _c(
          "span",
          {
            staticStyle: {
              "font-family": "微软雅黑",
              color: "rgb(51, 51, 51)",
              "font-size": "14px"
            }
          },
          [_vm._v("③演示机特价转销售的保修条款以具体购销合同为准；")]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticStyle: {
          margin: "0 0 21px",
          "font-family": "'Times New Roman'",
          "font-size": "16px",
          "text-wrap": "wrap",
          "line-height": "21px"
        }
      },
      [
        _c(
          "span",
          {
            staticStyle: {
              "font-family": "微软雅黑",
              color: "rgb(51, 51, 51)",
              "font-size": "14px"
            }
          },
          [
            _c("span", { staticStyle: { "font-family": "微软雅黑" } }, [
              _vm._v("北京毅力澎湃技术有限公司")
            ]),
            _c("span", { staticStyle: { "font-family": "微软雅黑" } }, [
              _vm._v("2023年")
            ])
          ]
        ),
        _c(
          "span",
          {
            staticStyle: {
              "font-family": "微软雅黑",
              color: "rgb(51, 51, 51)",
              "font-size": "14px"
            }
          },
          [_vm._v("9")]
        ),
        _c(
          "span",
          {
            staticStyle: {
              "font-family": "微软雅黑",
              color: "rgb(51, 51, 51)",
              "font-size": "14px"
            }
          },
          [_vm._v("月")]
        ),
        _c(
          "span",
          {
            staticStyle: {
              "font-family": "微软雅黑",
              color: "rgb(51, 51, 51)",
              "font-size": "14px"
            }
          },
          [_vm._v("12")]
        ),
        _c(
          "span",
          {
            staticStyle: {
              "font-family": "微软雅黑",
              color: "rgb(51, 51, 51)",
              "font-size": "14px"
            }
          },
          [_vm._v("日")]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("br")])
  }
]
render._withStripped = true

export { render, staticRenderFns }