var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        title: _vm.title,
        width: 800,
        visible: _vm.visible,
        confirmLoading: _vm.confirmLoading,
        okButtonProps: { props: { disabled: _vm.disableSubmit } },
        cancelText: "关闭"
      },
      on: { ok: _vm.handleOk, cancel: _vm.handleCancel }
    },
    [
      _vm.editStatus
        ? _c(
            "a-spin",
            { attrs: { spinning: _vm.confirmLoading } },
            [
              _c(
                "a-form-model",
                {
                  ref: "form",
                  attrs: { model: _vm.model, rules: _vm.validatorRules }
                },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        labelCol: _vm.labelCol,
                        wrapperCol: _vm.wrapperCol,
                        label: "客户姓名",
                        prop: "name",
                        required: "",
                        hasFeedback: ""
                      }
                    },
                    [
                      _c("a-input", {
                        attrs: {
                          placeholder: "请输入客户姓名",
                          readOnly: _vm.disableSubmit
                        },
                        model: {
                          value: _vm.model.name,
                          callback: function($$v) {
                            _vm.$set(_vm.model, "name", $$v)
                          },
                          expression: "model.name"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        labelCol: _vm.labelCol,
                        wrapperCol: _vm.wrapperCol,
                        label: "性别",
                        hasFeedback: ""
                      }
                    },
                    [
                      _c(
                        "a-select",
                        {
                          attrs: { placeholder: "请选择性别" },
                          model: {
                            value: _vm.model.sex,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "sex", $$v)
                            },
                            expression: "model.sex"
                          }
                        },
                        [
                          _c("a-select-option", { attrs: { value: "1" } }, [
                            _vm._v("男性")
                          ]),
                          _c("a-select-option", { attrs: { value: "2" } }, [
                            _vm._v("女性")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        labelCol: _vm.labelCol,
                        wrapperCol: _vm.wrapperCol,
                        label: "身份证号码",
                        prop: "idcard",
                        hasFeedback: ""
                      }
                    },
                    [
                      _c("a-input", {
                        attrs: {
                          placeholder: "请输入身份证号码",
                          readOnly: _vm.disableSubmit
                        },
                        model: {
                          value: _vm.model.idcard,
                          callback: function($$v) {
                            _vm.$set(_vm.model, "idcard", $$v)
                          },
                          expression: "model.idcard"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        labelCol: _vm.labelCol,
                        wrapperCol: _vm.wrapperCol,
                        label: "身份证扫描件",
                        hasFeedback: ""
                      }
                    },
                    [
                      _c("j-image-upload", {
                        attrs: { text: "上传", isMultiple: true },
                        model: {
                          value: _vm.fileList,
                          callback: function($$v) {
                            _vm.fileList = $$v
                          },
                          expression: "fileList"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        labelCol: _vm.labelCol,
                        wrapperCol: _vm.wrapperCol,
                        label: "联系方式",
                        prop: "telphone",
                        hasFeedback: ""
                      }
                    },
                    [
                      _c("a-input", {
                        attrs: { readOnly: _vm.disableSubmit },
                        model: {
                          value: _vm.model.telphone,
                          callback: function($$v) {
                            _vm.$set(_vm.model, "telphone", $$v)
                          },
                          expression: "model.telphone"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        labelCol: _vm.labelCol,
                        wrapperCol: _vm.wrapperCol,
                        label: "订单号码",
                        hidden: _vm.hiding,
                        hasFeedback: ""
                      }
                    },
                    [
                      _c("a-input", {
                        attrs: { disabled: "disabled" },
                        model: {
                          value: _vm.model.orderId,
                          callback: function($$v) {
                            _vm.$set(_vm.model, "orderId", $$v)
                          },
                          expression: "model.orderId"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }